// TODO: unused, remove?
// clock
// eslint-disable-next-line no-redeclare,no-unused-vars
function time(el) {
  var date = new Date();
  var h = date.getHours(); // 0 - 23
  var m = date.getMinutes(); // 0 - 59

  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;

  var time = h + ':' + m;
  el.innerText = time;
  el.textContent = time;

  setTimeout(function () {
    time(el);
  }, 1000);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function getMinutesSince(timestamp) {
  return moment(timestamp).diff() / -60000;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function secondsToStr(time) {
  var minutes = String(Math.floor(time / 60)).padStart(2, 0);
  var seconds = String(time % 60).padStart(2, 0);
  return minutes + ':' + seconds;
}
